<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_mobile) + ')',
      height: '100vh',
      width: '100vw',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div
      style="margin-top: 20px; margin-right: 5%; margin-left: 5%"
      class="d-flex flex-column align-center justify-center"
    >
      <!--<v-img :src="icon" :width="iconWidth" class="mx-auto mb-5"></v-img>
        <h1 class="text-center" :style="styleTitle">{{ agenceName }}</h1>-->

      <h2
        class="text-center mb-4"
        :style="{
          color: WEBAPPPRO_TEXT_COLOR_1,
          fontFamily: WEBAPPPRO_FONT_FAMILY_1,
          fontSize: WEBAPPPRO_FONT_SIZE_1,
          fontWeight: 700,
          marginTop: '150px',
        }"
      >
        VOS PHOTOS
      </h2>
      <div
        class="text-center"
        :style="{
          color: WEBAPPPRO_TEXT_COLOR_2,
          fontSize: WEBAPPPRO_FONT_SIZE_3,
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          'font-weight': 'regular',
        }"
      >
        <p>Sélectionnez votre meilleure photo ou refaites-en une</p>
      </div>
      <v-row>
        <v-col
          cols="5"
          v-for="(validatedImage, index) in validatedImages"
          :key="validatedImage.id"
          class="mx-2"
        >
          <v-sheet
            :class="{ selected: choix[index] }"
            rounded="lg"
            width="150"
            height="200"
            @click="choice1(index, validatedImage)"
          >
            <v-row class="d-flex align-center flex-column flex-sm-row pa-2">
              <v-img
                :src="getImageSrc(validatedImage.photo, validatedImage.qrcode)"
                :class="{ imgCho: true, 'selected-image': choix[index] }"
                width="100%"
                height="100%"
                cover
              />
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <div class="d-flex justify-center">
        <v-btn
          :color="WEBAPPPRO_BUTTON_COLOR_1"
          :disabled="uploading"
          @click="saveAndContinue"
          :loading="uploading"
          :style="{
            color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
            boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
            borderRadius: WEBAPPPRO_BUTTON_BORDER_ROUND,
            'text-transform': 'none',
            marginBottom: '20px',
            marginTop: '40px',
            padding: '25px 100px',
            fontFamily: WEBAPPPRO_FONT_FAMILY_3,
            fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
            'font-weight': 'bold',
            'line-height': '18px',
            'letter-spacing': '0em',
            'text-align': 'center',
            width: '327px',
            height: '52px',
          }"
          >Continuer
        </v-btn>
      </div>
      <div
        :color="WEBAPPPRO_BUTTON_COLOR_1"
        :style="{
          color: WEBAPPPRO_BUTTON_COLOR_1,
          fontFamily: WEBAPPPRO_FONT_FAMILY_3,
          fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
          'font-weight': 'bold',
          'line-height': '18px',
        }"
      >
        <a
          class="centered-button mt-2"
          href="./../ShootPhoto.vue"
          style="color: inherit; text-decoration: underline"
        >
          Reprendre une photo
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'AddOfferEphoto',
  data() {
    return {
      validatedImages: localStorageService.getValue('validatedImages'),
      addOffer: localStorageService.getValue('addOfferId'),
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      timelineColor: localStorageService.getStyle(
        location.hostname,
        'timelineColor',
      ),
      color4: localStorageService.getStyle(location.hostname, 'color4'),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      color2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      items: [],
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
      choix: [],
    }
  },
  methods: {
    getImageSrc(uid, mainQrCodeUid) {
      return requestService.getImageDownloadAnonymouslyLinkV2(
        uid,
        localStorageService.getValue('shortliveApiKey'),
        mainQrCodeUid,
      )
    },
    choice1(index, validatedImage) {
      // Inverser l'état de sélection de l'image à l'index donné
      this.$set(this.choix, index, !this.choix[index])

      // Retirer la bordure des autres images et définir l'image sélectionnée
      if (this.choix[index]) {
        this.selectedImage = validatedImage
      } else {
        this.selectedImage = null
      }

      for (let i = 0; i < this.choix.length; i++) {
        if (i !== index) {
          this.$set(this.choix, i, false)
        }
      }
    },
    saveAndContinue() {
      const orderFormData = JSON.stringify({
        orderItems: [
          {
            quantity: 1,
            document: {
              photo: this.selectedImage.photo,
              signature: this.selectedImage.signature,
            },
            priceId: localStorageService.getValue('addOfferMainId'),
          },
          {
            quantity: 1,
            priceId: localStorageService.getValue('addOfferId'),
          },
        ],
      })
      requestService
        .post(
          `/order/stripeCheckout`,
          orderFormData,
          {},
          {
            'content-type': 'application/json',
            shortlive_api_key: localStorageService.getValue('shortliveApiKey'),
          },
        )
        .then(data => {
          localStorageService.setObject('orderId', data.data.id)
          localStorageService.setObject(
            'checkoutSessionId',
            data.data.checkoutSessionId,
          )
          if (data.data.amount == 0) {
            this.uploading = false
            this.$router.push({ name: 'Final' })
          } else if (data.data.amount == null || data.data.amount == 'null') {
            this.uploading = false
            this.$router.push({ name: 'AlertAgency' })
          } else {
            this.uploading = false
            this.$router.push({ name: 'Payment' })
            //this.$router.push({ name: 'TreatmentPhoto' })
          }
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            'Erreur lors de la création de la commande.',
          )
          this.uploading = false
          this.$router.push({ name: 'NotAuthorized' })
        })
    },
  },
  mounted() {
    if (
      localStorageService.getValue('shortliveApiKey') === undefined ||
      localStorageService.getValue('rejectStatus') != undefined
    ) {
      this.$router.push({ name: 'NotAuthorized' })
    }
  },
}
</script>
<style>
.centered-button {
  color: inherit;
}
.imgCho {
  cursor: pointer;
}
.selected {
  border: 5px solid black;
}
.selected-image {
  border: 5px solid black; /* Bordure noire de 2px */
}
</style>
